import {onCLS, onFCP, onFID, onLCP, onTTFB, onINP} from 'web-vitals/attribution'
import {sendSoftVitals, sendTimingResults, sendVitals} from './timing-stats'
import {ssrSafeDocument} from '@github-ui/ssr-utils'
import {SOFT_NAV_STATE} from '@github-ui/soft-nav/states'
import {resetHPC} from './hpc'

export function setupWebVitals() {
  sendTimingResults()
  onCLS(sendVitals)
  onFCP(sendVitals)
  onFID(sendVitals)
  onLCP(sendVitals)
  onTTFB(sendVitals)
  onINP(sendVitals)

  onLCP(sendSoftVitals, {reportSoftNavs: true})
  onCLS(sendSoftVitals, {reportSoftNavs: true})
  onINP(sendSoftVitals, {reportSoftNavs: true})

  // Any time we trigger a new soft navigation, we want to reset HPC.
  ssrSafeDocument?.addEventListener(SOFT_NAV_STATE.START, ({mechanism}) => {
    resetHPC({soft: true, mechanism})
  })

  // Start HPC at page load.
  resetHPC({soft: false})
}
